<template>
  <auth-content>
    <el-header slot="header">
      <el-header-action v-if="isWorldOwner" size="xs" slot="actions" icon="envelop2" :label="$t('user.list.invite_users')" @click.native="openInviteModal()"/>
      <el-header-action v-if="isWorldOwner" size="xs" slot="actions" icon="add" :label="$t('user.list.create_user')" @click.native="$router.push({name: 'world.users.add'})"/>
    </el-header>

    <el-filters :filters="filters"/>

    <div v-for="(user, index) in usersFiltered" v-if="user">
      <el-user-teaser
        :user="user"
      >
        <div slot="actions">

          <el-table-action
            v-if="isWorldOwner && user.id !== currentUserId && !user.owner && !user.invitation && (user.pivot && user.pivot.internal)"
            @click.prevent.native="changeOwnerStatus(user.id, true)"
            :title="$t('user.list.set_owner')"
            icon="icon-crown"
          />
          <el-table-action
            v-if="isWorldOwner && user.id !== currentUserId && user.owner && !user.invitation && (user.pivot && user.pivot.internal)"
            @click.prevent.native="changeOwnerStatus(user.id, false)"
            :title="$t('user.list.unset_owner')"
            icon="icon-crown"
          />

          <el-table-action
            v-if="isWorldOwner && user.id !== currentUserId && !(user.pivot && user.pivot.internal) && !user.invitation && !user.pivot.owner"
            @click.prevent.native="changeInternalStatus(user.id, true)"
            :title="$t('user.list.set_internal')"
            icon="icon-user-check"
          />
          <el-table-action
            v-if="isWorldOwner && user.id !== currentUserId && (user.pivot && user.pivot.internal) && !user.invitation && !user.pivot.owner"
            @click.prevent.native="changeInternalStatus(user.id, false)"
            :title="$t('user.list.unset_internal')"
            icon="icon-user-lock"
          />

          <el-table-action
            v-if="isWorldOwner && user.id !== currentUserId && !user.invitation"
            @click.prevent.native="resendUserPassword(user.email)"
            :title="$t('user.list.resend_password')"
            icon="icon-lock2"
          />
          <el-table-action
            v-if="isWorldOwner && user.id !== currentUserId && !user.invitation"
            @click.prevent.native="confirmUserRemove(user.id)"
            :title="$t('user.list.remove_user')"
            icon="icon-bin"
          />
          <el-table-action
            v-if="isWorldOwner && user.invitation"
            @click.prevent.native="relaunchInvitation(user.email)"
            :title="$t('user.list.relaunch_invitation')"
            icon="icon-reload-alt"
          />
          <el-table-action
            v-if="isWorldOwner && user.invitation"
            @click.prevent.native="removeInvitation(user.invitation_id)"
            :title="$t('user.list.remove_invitation')"
            icon="icon-bin"
          />

        </div>
      </el-user-teaser>
    </div>

    <el-modal size="md" :title="$t('user.list.invite_users')" id="user-world-invite">
      <form @submit.prevent="sendInvitation()">
        <div class="row no-gutters bordered-wide border rounded mb-2">
          <label class="type-label cursor-pointer col-md-6 m-0">
            <div class="p-2 d-flex">
              <div class="">
                <input type="radio" name="type" v-model="invite_user_type" value="internal" class="d-none">
                <i class="icon-radio-checked2 text-success mr-2" v-if="invite_user_type == 'internal'" />
                <i class="icon-radio-unchecked mr-2" v-else />
              </div>
              <div>
                <div class="font-weight-bold">{{ $t('common.internal') }}</div>
                <div class="small">{{ $t('common.internal_desc') }}</div>
              </div>
            </div>
          </label>
          <label class="type-label cursor-pointer col-md-6 m-0 bordered-wide border-left">
            <div class="p-2 d-flex">
              <div class="">
                <input type="radio" name="type" v-model="invite_user_type" value="external" class="d-none">
                <i class="icon-radio-checked2 text-success mr-2" v-if="invite_user_type == 'external'" />
                <i class="icon-radio-unchecked mr-2" v-else />
              </div>
              <div>
                <div class="font-weight-bold">{{ $t('common.external') }}</div>
                <div class="small">{{ $t('common.external_desc') }}</div>
              </div>
            </div>
          </label>
        </div>
        <div class="form-group">
          <label for="invite_emails" class="font-weight-semibold">{{ $t('user.list.invite_users_help') }}</label>
          <TextareaAutosize
            ref="invite_emails_textarea"
            v-model="invite_emails"
            required="required"
            class="form-control"
            :placeholder="$t('user.list.invite_users_placeholder')"
            id="invite_emails"
          />
        </div>
        <div class="text-center">
          <el-alert v-tooltip="$t('subscription.explanation')" type="success" v-if="worldHasSubscription && world.plan.free_invitations_left < 1">
            <div class="small">
              {{ $t('user.list.invitation_confirmation_text') }}<br/>
              <span class="border-bottom cursor-pointer" @click="closeAndGoToSubscriptionPage()">
                {{ $t('user.list.invitation_confirmation_pricing_link') }}
              </span>
            </div>
          </el-alert>
          <button v-if="!invite_loading" type="submit" class="btn btn-success text-white">{{ $t('common.send') }}</button>
          <div v-if="invite_loading" class="text-primary pt-1 pb-2"><loader /></div>
        </div>
      </form>
    </el-modal>

    <el-modal size="sm" :title="$t('user.list.remove_user')" id="user-world-remove">
      <form @submit.prevent="removeUser()">
        <div v-if="!remove_loading" class="form-check mb-3">
          <label class="form-check-label">
            <input type="checkbox" required class="form-check-input" v-model="remove_user_confirm">
            {{ $t('user.list.remove_user_world_confirmation_text') }}
          </label>
        </div>
        <div class="text-center">
          <button v-if="!remove_loading" type="submit" class="btn btn-danger text-white">{{ $t('common.submit') }}</button>
          <div v-if="remove_loading" class="text-primary pt-1 pb-2"><loader /></div>
        </div>
      </form>
    </el-modal>
  </auth-content>
</template>


<script>

import Fuse from 'fuse.js'
import _ from 'lodash'
import store from '../../store'
import {
  AUTH_PASSWORD_RESET,
  WORLD_USERS_REQUEST,
  WORLD_INVITATIONS_REQUEST,
  WORLD_INVITATION_SEND,
  WORLD_OWNER_STATUS_CHANGE,
  WORLD_INTERNAL_STATUS_CHANGE,
  WORLD_USER_REMOVE,
  WORLD_INVITATION_REMOVE,
} from '../../store/mutations-types'

export default {
  name: 'WorldUsersList',
  tag: 'world-users-list',
  data () {
    return {
      searchValue: '',
      remove_user_id: null,
      remove_user_confirm: false,
      invite_emails: '',
      invite_user_type: 'internal',
      remove_loading: false,
      invite_loading: false,
      invitations: false,
      users: false,
      loading: false,
      columns: {
        first_name: this.$t('form.user.first_name'),
        last_name: this.$t('form.user.last_name'),
        email: this.$t('form.user.email'),
        phone_number: this.$t('form.user.phone_number'),
        position: this.$t('form.user.position')
      },
      filters: {}
    }
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('world/' + WORLD_USERS_REQUEST, to.params.world).then(users => {
      next(vm => {
        vm.users = _.sortBy({...users}, (user) => { return !user.pivot.owner })
      })
    })
  },
  created () {
    // Load data after page init to speed up the page rendering
    this.loadInvitations()
  },
  methods: {
    openInviteModal() {
      this.openModal('user-world-invite')
      setTimeout(() => {
        this.$refs.invite_emails_textarea.$el.focus()
      }, 200)
    },
    loadUsers () {
      this.$store.dispatch('world/' + WORLD_USERS_REQUEST, this.$route.params.world).then(users => {
        this.users = users
        this.loadFilters()
      })
    },
    loadInvitations () {
      if (this.isWorldOwner) {
        this.$store.dispatch('world/' + WORLD_INVITATIONS_REQUEST, this.$route.params.world).then(invitations => {
          this.invitations = invitations
          this.loadFilters()
        })
      } else {
        this.loadFilters()
      }
    },
    loadFilters () {
      let users = {...this.formatUsers()}
      this.filters = Object.assign({}, this.filters, [
        {
          title: this.$t('form.user.status'),
          key: 'status',
          options: this.getFilterOptions(users, 'status'),
        },
        {
          title: this.$t('common.internal'),
          key: 'internal',
          options: this.getFilterOptions(users, 'internal'),
        },
        {
          title: this.$t('form.user.tribes'),
          key: 'tribes',
          options: this.getFilterOptions(users, 'tribes')
        },
        {
          title: this.$t('form.user.position'),
          key: 'position',
          options: this.getFilterOptions(users, 'position')
        }
      ])
    },
    sendInvitation () {
      this.invite_loading = true
      this.$store.dispatch('world/' + WORLD_INVITATION_SEND, {
        worldId: this.$route.params.world,
        emails: this.invite_emails,
        internal: (this.invite_user_type == 'internal'),
      }).then(data => {
        this.invite_loading = false
        this.notifSuccess(data)
        this.loadInvitations()
        this.closeModal('user-world-invite')
        this.invite_emails = ''
      })
      .catch(error => {
        this.invite_loading = false
        this.notifError(error)
      })
    },
    relaunchInvitation (email) {
      this.$store.dispatch('world/' + WORLD_INVITATION_SEND, {
        worldId: this.$route.params.world,
        emails: email,
      }).then(data => {
        this.notifSuccess(data)
        this.loadInvitations()
      })
      .catch(error => {
        this.notifError(error)
      })
    },
    changeOwnerStatus (user_id, status) {
      this.$store.dispatch('world/' + WORLD_OWNER_STATUS_CHANGE, {
        worldId: this.$route.params.world,
        userId: user_id,
        status: status,
      }).then(data => {
        this.notifSuccess(data)
        this.loadUsers()
      })
      .catch(error => {
          this.notifError(error)
      })
    },
    changeInternalStatus (user_id, status) {
      this.$store.dispatch('world/' + WORLD_INTERNAL_STATUS_CHANGE, {
        worldId: this.$route.params.world,
        userId: user_id,
        status: status,
      }).then(data => {
        this.notifSuccess(data)
        this.loadUsers()
      })
      .catch(error => {
          this.notifError(error)
      })
    },
    resendUserPassword (user_email, status) {
      this.$store.dispatch('auth/' + AUTH_PASSWORD_RESET, user_email).then(data => {
        this.notifSuccess(this.$t('user.list.resend_password_confirm'))
      })
      .catch(error => {
          this.notifError(error)
      })
    },
    removeInvitation (invitation_id) {
      this.$store.dispatch('world_invitation/' + WORLD_INVITATION_REMOVE, {
        worldId: this.$route.params.world,
        invitationId: invitation_id
      }).then(data => {
        this.notifSuccess(data)
        this.loadInvitations()
      })
      .catch(error => {
          this.notifError(error)
      })
    },
    confirmUserRemove (userId) {
      this.remove_user_confirm = false
      this.remove_user_id = userId
      this.openModal('user-world-remove');
    },
    removeUser() {
      if (this.remove_user_confirm) {
        this.remove_loading = true
        this.$store.dispatch('world/' + WORLD_USER_REMOVE, {
          worldId: this.$route.params.world,
          userId: this.remove_user_id
        }).then(data => {
          this.remove_loading = false
          this.notifSuccess(data)
          this.loadUsers()
          this.closeModal('user-world-remove')
          this.remove_user_id = null
          this.remove_user_confirm = false
        })
        .catch(error => {
          this.remove_loading = false
          this.notifError(error)
        })
      }
    },
    formatUsers () {
      let users = []

      if (this.users) {
        // Get users
        _.map(this.users, (elem) => {
          let user = {
            id: elem.id,
            pivot: elem.pivot,
            link: this.$router.resolve({name: 'world.users.profile', params: {world : this.$route.params.world, user : elem.id}}).href,
            avatar: elem.avatar.thumb,
            email: elem.email,
            first_name: elem.first_name,
            last_name: elem.last_name,
            phone_number: elem.phone_number,
            phone_search: elem.phone_number ? elem.phone_number.replace(elem.phone_number.split(' ')[0], '0').replace(/\s/g,'') : '',
            tribes: elem.tribes ? elem.tribes.map(tribe => tribe.name) : [],
            position: elem.position,
            birthday: elem.birthday,
            owner: elem.pivot.owner == true,
            status: elem.pivot.owner == true ? this.$t('user.list.owner') : '',
            internal: elem.pivot.internal == true ? this.$t('common.yes') : this.$t('common.no'),
          }
          users.push(user);
        })
      }

      if (this.invitations) {
        // Get invits
        _.map(this.invitations, (elem) => {
          users.push({
            invitation_id: elem.id,
            avatar: elem.avatar.thumb,
            email: elem.email,
            internal: elem.internal == true ? this.$t('common.yes') : this.$t('common.no'),
            pivot: {
              internal: elem.internal,
            },
            first_name: null,
            last_name: null,
            phone_number: null,
            position: null,
            birthday: null,
            invitation: true,
            status: this.$t('user.list.invited')
          })
        })
      }
      return users
    },
    closeAndGoToSubscriptionPage () {
      this.$router.push({name: 'world.subscription', params: {world: this.$route.params.world}})
      this.closeModal('user-world-invite')
    }
  },
  computed: {
    world () {
      return this.$store.getters['auth/getWorld']
    },
    worldHasSubscription () {
      return this.$store.getters['auth/worldHasSubscription']
    },
    currentUserId () {
      return this.$store.getters['auth/getId']
    },
    isWorldOwner () {
      return this.$store.getters['auth/isWorldOwner']
    },
    usersFormatted () {
      return this.formatUsers()
    },
    usersFiltered () {

      let searchValue = this.$store.state.global.sidebarSearch
      if (searchValue === '') {
        return this.applyDataFilters(this.usersFormatted, this.filters)
      }

      return new Fuse(this.applyDataFilters(this.usersFormatted, this.filters), {
        shouldSort: false,
        threshold: 0.3,
        location: 0,
        distance: 100,
        minMatchCharLength: 1,
        keys: [
          "email",
          "first_name",
          "last_name",
          "phone_number",
          "phone_search",
          "position",
        ]
      }).search(searchValue)
    }
  }
}
</script>

<style lang="scss" scoped>
img.preview {
  object-fit: cover;
  width: 35px;
  height: 35px;
}
::v-deep .avatar img.small {
  height: 40px;
  width: 40px;
}
.button-icon-action {
  display: inline-block;
  border-radius: 100px;
  line-height: 35px;
  width: 35px;
  height: 35px;
  text-align:center;
  background: #eee;
  margin-left:6px;
  cursor: pointer;
  transition: .1s all ease;
  &:hover {
    background: #333;
    i {
      color: #eee;
    }
  }
}
.bordered-wide {
  border-width: 2px !important;
}
.type-label {
  &:hover {
    background: rgba(black, .03);
  }
}
</style>
